<template>
  <div>
    <div class="container-hint small" style="display:inline-flex">
      <span class="inline-block align-middle">人物篩選：&nbsp;</span>
      <el-checkbox-group
        v-model="comparePersonFilter">
        <el-checkbox
          :label="item.value"
          :key="item.value"
          v-for="item in comparePersonOptions">
          {{item.label}}
        </el-checkbox>
      </el-checkbox-group>
    </div>
    <!-- companys -->
    <div
      class="company-container"
      v-loading="isPersonDataLoading"
      v-if="currentCompareItem=='companys'">
      <section
        :key="person.value"
        v-for="(person,index) in comparePersonOptions">
        <div v-show="comparePersonFilter.includes(person.value)">
          <RelatedCompanyListContainer
            v-loading="isPersonDataLoading"
            :title="basicList[index].keyword"
            :companys="multiPersonData[index].companys"
            :queryCondition="queryConditionList[index]">
          </RelatedCompanyListContainer>
        </div>
      </section>
    </div>
    <!-- verdicts -->
    <div
      class="company-container"
      v-loading="isPersonDataLoading"
      v-else-if="currentCompareItem=='verdicts'">
      <section
        :key="person.value"
        v-for="(person,index) in comparePersonOptions">
        <div v-show="comparePersonFilter.includes(person.value)">
          <VerdictsTable
            :title="person.label"
            :verdicts="multiPersonData[index].verdicts"
            :basic="multiPersonData[index].basic"
            :uniID="person.value">
          </VerdictsTable>
        </div>
      </section>
    </div>
    <!-- news -->
    <div
      class="company-container"
      v-loading="isPersonDataLoading"
      v-else-if="currentCompareItem=='news'">
      <section
        :key="person.value"
        v-for="(person,index) in comparePersonOptions">
        <div v-show="comparePersonFilter.includes(person.value)">
          <NewsTable
            :title="person.label"
            :news="multiPersonData[index].news"
            :uniID="person.value">
          </NewsTable>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import * as apis from '@/apis/index.ts'
import { mapState } from 'vuex'
import RelatedCompanyListContainer from '@/components/RelatedCompanyListContainer/RelatedCompanyListContainer.vue'
import VerdictsTable from '@/components/VerdictsTable/VerdictsTable.vue'
import NewsTable from '@/components/NewsTable/NewsTable.vue'

export default {
  props: ['menuIndex'],
  components: {
    RelatedCompanyListContainer,
    VerdictsTable,
    NewsTable
  },
  data () {
    return {
      comparePersonFilter: []
    }
  },
  computed: {
    ...mapState('multiPersonDetail', [
      'basicList',
      'getList',
      'multiPersonData',
      'multiPersonNews',
      'isPersonDataLoading',
      'isPersonNewsLoading'
    ]),
    // 目前的比較項目
    currentCompareItem () {
      return this.menuIndex
    },
    comparePersonOptions () {
      return this.basicList.map(d => {
        return {
          label: d.keyword,
          value: d.keyword
        }
      })
    },
    queryConditionList () {
      return this.basicList.map(d => {
        return {
          basic: d
        }
      })
    }
  },
  methods: {
    returnCompanyList (index) {
      if (this.multiPersonData.length) {
        return this.multiPersonData[index].companys.list
      } else {
        return []
      }
    },
    returnCompanyCount (index) {
      if (this.multiPersonData.length) {
        return this.multiPersonData[index].companys.stat['total-count'] || 0
      } else {
        return 0
      }
    }
  },
  created () {
  },
  watch: {
    'comparePersonOptions': {
      handler (d) {
        this.comparePersonFilter = d.map(d => d.value)
      },
      immediate: true
    },
  }
}
</script>
